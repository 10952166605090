<template>
    <div>
        <div class="main-container">
            <div class="container codigo">
                <h3>Código de Barras</h3>
                <h2>{{ produto.codigo_de_barras }}</h2>
            </div>
            <div class="container descricao">
                <h3>Descrição</h3>
                <h2>{{ produto.descricao }}</h2>
            </div>
            <div class="container validade">
                <h3>Validades Incluídas</h3>
                <h2>
                    <ul>
                        <li
                            class="linha-validade"
                            v-for="(validades, index) in produto.validadesAdicionais"
                            :key="validades.validade"
                        >
                            {{ dataFormatada(validades.validade) }} - QTD: {{ validades.quantidade }}
                            <div class="container-buttons">
                                <router-link
                                    :to="{
                                        name: 'produtos.edit',
                                        params: {
                                            codigo: produto.codigo_de_barras,
                                            validade: validades.validade,
                                            quantidade: validades.quantidade,
                                        },
                                    }"
                                    class="btn-edit-validade"
                                >
                                    <img src="../assets/img/edit.svg" alt="Editar" />
                                </router-link>
                                <button
                                    v-if="produto.validadesAdicionais.length > 1"
                                    @click.prevent="excluiValidade(validades.validade, index)"
                                    class="btn-remove-validade"
                                >
                                    <img src="../assets/img/close-black.svg" alt="Remover validade" />
                                </button>
                            </div>
                        </li>
                    </ul>
                </h2>
            </div>
            <div class="container produto">
                <h3>Produto</h3>
                <div class="container-img">
                    <img
                        :src="
                            'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                            produto.codigo_de_barras +
                            '.webp'
                        "
                        @error="replaceByDefault"
                        alt="imagem do produto"
                    />
                </div>
            </div>
        </div>

        <!-- <router-link
            :to="{
                name: 'produtos.edit',
                params: {
                    codigo: produto.Codigo_de_Barras,
                    validade: produto.Data_de_Validade,
                },
            }"
            class="btn-add-container"
        >
            <img src="../assets/img/edit-white.svg" alt="botão para adicionar produtos" />
        </router-link> -->
    </div>
</template>

<script>
import img from '../assets/img/broken-image.svg';
import HTTP from '../http/http.js';

export default {
    data() {
        return {
            produto: {},
        };
    },

    computed: {
        getProduto() {
            return this.$store.getters.getProduto;
        },
        getCodigo() {
            return this.$store.getters.getCodigo;
        },
    },

    created() {
        let codigo = this.$route.params.codigo ? this.$route.params.codigo : this.getCodigo;

        this.$store.dispatch('setCodigo', codigo);

        this.produto = this.getProduto;
    },

    methods: {
        dataFormatada(diaDB) {
            if (diaDB != '') {
                let dateSplit = diaDB.split('-');
                let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
                return newDate;
            } else {
                return '';
            }
        },

        replaceByDefault(e) {
            e.target.src = img;
        },

        excluiValidade(validade, index) {
            this.removeValidade(index);

            let codigo_de_barras = this.produto.codigo_de_barras;

            let data = {
                data_validade: validade,
                codigo_de_barras: codigo_de_barras,
            };

            let loja = sessionStorage.getItem('loja');

            HTTP.delete(`/csd/${loja}/tarefas-inclusao`, { data: data })
                .then(() => {
                    this.isSending = false;
                    this.showSucess = true;
                })
                .catch(() => {
                    /* this.isInvalid = true; */
                    console.error('erro');
                });
        },

        removeValidade(currentIndex) {
            this.produto.validadesAdicionais.splice(
                this.produto.validadesAdicionais.findIndex((a, index) => index == currentIndex),
                1
            );
        },
    },
};
</script>

<style scoped>
.main-container {
    margin-top: 56px;
    padding: 20px;
    margin-bottom: 50px;
}

.container {
    color: #3c4043;
    margin-bottom: 14px;
}

.container h3 {
    font-size: 0.6em;
}

.container h2 {
    font-size: 0.9em;
    margin-top: 4px;
}

.container-img {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-top: 4px;
}

.container-img img {
    width: 80px;
    height: 80px;
}

footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--verde);
    box-shadow: 0 0 5px #cdcdcd;
}

footer a img {
    width: 18px;
}

.format-footer {
    place-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
}

.format-footer:last-child {
    background-color: #fff;
}

.format-footer h3 {
    font-size: 12px;
    margin-top: 5px;
    color: var(--branco);
    font-weight: 500;
}

.inclusao h3 {
    color: #4e4e4e;
}

.btn-add-container {
    width: 56px;
    height: 56px;
    display: flex;
    place-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--verde);
    position: fixed;
    bottom: 86px;
    left: 80%;
    box-shadow: 0 0 5px #000;
}

.btn-add-container img {
    width: 24px;
    height: 24px;
}

.linha-validade {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    font-size: 15px;
    margin-bottom: 10px;
    border: 1px solid #d8d8d8;
    padding: 5px;
    border-radius: 10px;
}

.container-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.btn-edit-validade {
    background-color: #e3e3e3;
    border: none;
    border-radius: 10px;
    padding: 5px;
    display: inline-flex;
    cursor: pointer;
    justify-self: flex-end;
}

.btn-edit-validade:hover {
    background-color: #f4f4f4;
}

.btn-edit-validade img {
    width: 18px;
}

.btn-remove-validade {
    background-color: #e3e3e3;
    border: none;
    border-radius: 10px;
    padding: 5px;
    display: inline-flex;
    cursor: pointer;
    justify-self: flex-end;
}

.btn-remove-validade:hover {
    background-color: #f4f4f4;
}

.btn-remove-validade img {
    width: 18px;
}

@media (max-width: 319px) {
    .container h2 {
        font-size: 0.8rem;
    }

    .btn-add-container {
        left: 76%;
    }
}
</style>
